import React, { useState, useEffect } from "react";
import { Button, Input, Select, Typography, Space } from "antd";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const { Option } = Select;
const { Text } = Typography;

const weightProfiles = {
    BASE: {
        Запрос: 1.7,
        Отчет: 0.7,
        Платеж: 0.9,
        Лимит: 1,
    },
    PRO: {
        Запрос: 1.8,
        Отчет: 0.9,
        Платеж: 1,
        Лимит: 1,
    },
    VIP: {
        Запрос: 2,
        Отчет: 0.9,
        Платеж: 1,
        Лимит: 1,
    },
};

const Median = () => {
    const [fields, setFields] = useState([
        { id: 1, value: "", type: "Запрос" },
    ]);
    const [taskCount, setTaskCount] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    const [currentProfile, setCurrentProfile] = useState("BASE");

    const weights = weightProfiles[currentProfile];

    // Загрузка данных из localStorage при монтировании компонента
    useEffect(() => {
        const savedFields = localStorage.getItem("fields");
        const savedProfile = localStorage.getItem("profile");
        if (savedFields) {
            const parsedFields = JSON.parse(savedFields);
            setFields(parsedFields);
            setTaskCount(parsedFields.length);
            setTotalPoints(
                parsedFields.reduce((sum, field) => sum + weights[field.type], 0)
            );
        }
        if (savedProfile) {
            setCurrentProfile(savedProfile);
        }
    }, []);

    // Сохранение данных в localStorage при каждом обновлении `fields` или `currentProfile`
    useEffect(() => {
        localStorage.setItem("fields", JSON.stringify(fields));
    }, [fields]);

    useEffect(() => {
        localStorage.setItem("profile", currentProfile);
        setTotalPoints(
            fields.reduce((sum, field) => sum + weightProfiles[currentProfile][field.type], 0)
        );
    }, [currentProfile]);

    const handleAddField = async () => {
        const clipboardText = await navigator.clipboard.readText();
        const newField = { id: fields.length + 1, value: clipboardText, type: "Запрос" };
        const updatedFields = [...fields, newField];
        setFields(updatedFields);
        setTaskCount(updatedFields.length);
        setTotalPoints(
            updatedFields.reduce((sum, field) => sum + weights[field.type], 0)
        );
    };

    const handleInputChange = (id, value) => {
        const updatedFields = fields.map((field) =>
            field.id === id ? { ...field, value } : field
        );
        setFields(updatedFields);
    };

    const handleTypeChange = (id, type) => {
        const updatedFields = fields.map((field) =>
            field.id === id ? { ...field, type } : field
        );
        setFields(updatedFields);
        setTotalPoints(
            updatedFields.reduce((sum, field) => sum + weights[field.type], 0)
        );
    };

    const handleProfileChange = (profile) => {
        setCurrentProfile(profile);
    };

    const handleClear = () => {
        setFields([{ id: 1, value: "", type: "Запрос" }]);
        setTaskCount(0);
        setTotalPoints(0);
        localStorage.removeItem("fields");
    };

    const handleDownload = () => {
        const data = fields.map(({ id, value, type }) => ({
            ID: id,
            "Client ID": value,
            Type: type,
            Weight: weights[type],
        }));
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Client IDs");
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, "client_ids.xlsx");
    };

    return (
        <div style={{ padding: 24 }}>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Text strong>Список клиентов</Text>

                <Select
                    value={currentProfile}
                    onChange={handleProfileChange}
                    style={{ marginBottom: 16, width: 200 }}
                >
                    <Option value="BASE">BASE</Option>
                    <Option value="PRO">PRO</Option>
                    <Option value="VIP">VIP</Option>
                </Select>

                {fields.map((field) => (
                    <Space key={field.id} style={{ marginBottom: 8 }}>
                        <Input
                            placeholder="Введите ID клиента"
                            value={field.value}
                            onChange={(e) => handleInputChange(field.id, e.target.value)}
                            style={{ width: 200 }}
                        />
                        <Select
                            value={field.type}
                            onChange={(value) => handleTypeChange(field.id, value)}
                            style={{ width: 120 }}
                        >
                            <Option value="Запрос">Запрос</Option>
                            <Option value="Отчет">Отчет</Option>
                            <Option value="Платеж">Платеж</Option>
                            <Option value="Лимит">Лимит</Option>
                        </Select>
                    </Space>
                ))}

                <Button type="dashed" onClick={handleAddField} style={{ width: "100%" }}>
                    Добавить поле
                </Button>

                <Space style={{ marginTop: 16, justifyContent: "space-between" }}>
                    <Text>Заданий выполнено: {taskCount}</Text>
                    <Text>Сумма баллов: {totalPoints.toFixed(2)}</Text>
                </Space>

                <Space style={{ marginTop: 16, justifyContent: "space-between", width: "100%" }}>
                    <Button type="default" onClick={handleClear}>
                        Очистить
                    </Button>
                    <Button type="primary" onClick={handleDownload}>
                        Скачать в формате Excel
                    </Button>
                </Space>
            </Space>
        </div>
    );
};

export default Median;