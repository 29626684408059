import React, { useState } from "react";
import { Input, Select, Button, Space, Typography } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import Recomends from "../Recomends/Recomends";
import './Comment.css';

const { Option } = Select;
const { Title } = Typography;

const Comment = ({ onAdd }) => {
    const [note, setNote] = useState("");
    const [title, setTitle] = useState("");
    const [documents, setDocuments] = useState([]);
    const [explanation, setExplanation] = useState("");
    const [result, setResult] = useState([]);
    const [measures, setMeasures] = useState([]);
    const [inputType, setInputType] = useState([]);
    const [outputType, setOutputType] = useState([]);
    const [media, setMedia] = useState("");
    const [positive, setPositive] = useState([]);
    const [negative, setNegative] = useState([]);
    const [mt, setMt] = useState("");

    const clientTypes = [
        "Клиент на документах",
        "Клиент из отчета",
        "Клиент на платеже",
        "Клиент на исходящем p2p платеже",
        "Клиент на лимите",
    ];

    const documentOptions = [
        "2-ндфл",
        "3-ндфл",
        "Выписка из ВТБ",
        "Выписка из Точки",
        "Выписка из Альфы",
        "Выписка из Сбера",
        "Скрин из казино",
        "Скрины крипто операций",
        "Скрины переписок",
        "ДКП",
        "ДКП авто",
        "ДКП недвижимости"
    ];

    const resultOptions = [
        "Клиент признался в НПД.",
        "По счету есть риски 16-МР.",
        "По счету есть риски 53-Т.",
        "По счету есть риски 172-Т.",
        "Клиент признался в криптообмене и предоставил скрины из биржи",
        "Крит. рисков нет",
        "Отпускаю с НВР на НПД",
        "Отпускаю с НВР на ПД по счету ФЛ",
        "Отпускаю с НВР на транзит",
        "Отпускаю с НВР на СЗП",
        "Отпускаю с НВР на ЛОМ",
        "Рекомендую закрытие",
        "Отпускаю без мер"
    ];

    const measureOptions = [
        "Успешная проверка",
        "НВР",
        "Закрытие счета",
        "ПП",
        "Дозапрос",
        "ВК",
        "Отказ в увеличении",
        "Отказ + запрос",
        "Увеличение до запрашиваемого",
        "Увеличение х2"
    ];

    const inputOutputOptions = ["ФЛ", "ЮЛ", "ИП", "нал", "покупки", "займы", "цупис"];

    const positiveOptions = ["продукты банка", "ДО", "жизнь", "нет"];
    const negativeOptions = ["транзит", "обнал", "нет жизни", "нет"];

    const updateNote = () => {
        const formattedNote = `
${title ? `${title}\n` : ""}
${inputType.length ? `Общая информация\nНа вход: ${inputType.join(" + ")}` : ""}
${outputType.length ? `На выход: ${outputType.join(" + ")}\n` : ""}
${documents.length ? `Документы: ${documents.join(", ")}\n` : ""}
${positive ? `Позитив: ${positive}\n` : ""}
${negative ? `Негатив: ${negative}\n` : ""}
${explanation ? `Пояснение: ${explanation}\n` : ""}
${media ? `СМИ: ${media}\n` : ""}
${mt ? `MT: ${mt}\n` : ""}
${result.length ? `Итог: ${result.join(" ")}\n` : ""}
${measures.length ? `________________\nМеры: ${measures.join(" + ")}\n` : ""}`.trim();
        setNote(formattedNote);
    };

    const clearAll = () => {
        setTitle("");
        setDocuments([]);
        setExplanation("");
        setResult([]);
        setMeasures([]);
        setInputType([]);
        setOutputType([]);
        setMedia("");
        setPositive("");
        setNegative("");
        setMt("");
        setNote("");
    };

    // Функция для открытия нового окна с компонентом Comment
    const openCommentWindow = () => {
        const commentWindow = window.open('/comment', '_blank', 'width=400,height=800');
        // Вставляем содержимое компонента Comment в новое окно
        commentWindow.document.body.innerHTML = `<div id="comment-root"></div>`;

        // Рендерим компонент Comment в новом окне
        const script = document.createElement('script');
        script.innerHTML = `
      const root = document.getElementById('comment-root');
      root.innerHTML = '<div id="comment"></div>';
      ReactDOM.render(React.createElement(${Comment}), document.getElementById('comment'));
    `;
        commentWindow.document.body.appendChild(script);
    };

    return (
        <Space direction="vertical" style={{ width: "100%" }} size="middle">
            <Recomends />
            <div className='box-title' level={4}>Конструктор заметки</div>
            <div className='comment-box'>
                <div>
                    <Input.TextArea
                        className="comment-area-box"
                        rows={6}
                        placeholder="Заметка будет автоматически заполняться на основе выбранных данных"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                    <Button onClick={openCommentWindow}>Открыть конструктор в отдельном окне</Button>
                </div>
                <div className='parametr-area'>
                    {/* Название заметки */}
                    <div className='box-title'>Вид таска</div>
                    <Select
                        className='param-select'
                        value={title}
                        onChange={(selectedTitle) => {
                            setTitle(selectedTitle);
                            updateNote();
                        }}
                        placeholder="Клиент на документах"
                        style={{ width: "100%" }}
                    >
                        {clientTypes.map((option) => (
                            <Option key={option} value={option}>
                                {option}
                            </Option>
                        ))}
                    </Select>

                    {/* Общая информация - На вход и На выход */}
                    <div style={{ width: "100%" }}>
                        <Title className='box-title' level={5}>Общая информация</Title>
                        <Space direction="horizontal" style={{ width: "100%" }} size="large">
                            <Select
                                mode="multiple"
                                allowClear
                                value={inputType}
                                onChange={(selectedInputType) => {
                                    setInputType(selectedInputType);
                                    updateNote();
                                }}
                                placeholder="На вход"
                                style={{ width: "200px" }}
                            >
                                {inputOutputOptions.map((option) => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>

                            <Select
                                className='param-select'
                                mode="multiple"
                                allowClear
                                value={outputType}
                                onChange={(selectedOutputType) => {
                                    setOutputType(selectedOutputType);
                                    updateNote();
                                }}
                                placeholder="На выход"
                                style={{ width: "200px" }}
                            >
                                {inputOutputOptions.map((option) => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                    </div>

                    {/* Документы */}
                    <Select
                        className='param-select'
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Выберите документы"
                        value={documents}
                        onChange={(selectedDocs) => {
                            setDocuments(selectedDocs);
                            updateNote();
                        }}
                    >
                        {documentOptions.map((doc) => (
                            <Option key={doc} value={doc}>
                                {doc}
                            </Option>
                        ))}
                    </Select>

                    {/* Позитив и Негатив */}
                    <div style={{ width: "100%" }}>
                        <Title className='box-title' level={5}>Позитив и Негатив</Title>
                        <Space direction="horizontal" style={{ width: "100%" }} size="large">
                            <Select
                                className='param-select'
                                mode="multiple"
                                allowClear
                                value={positive}
                                onChange={(selectedPositive) => {
                                    setPositive(selectedPositive);
                                    updateNote();
                                }}
                                placeholder="Выберите позитив"
                                style={{ width: "200px" }}
                            >
                                {positiveOptions.map((option) => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>

                            <Select
                                className='param-select'
                                mode="multiple"
                                allowClear
                                value={negative}
                                onChange={(selectedNegative) => {
                                    setNegative(selectedNegative);
                                    updateNote();
                                }}
                                placeholder="Выберите негатив"
                                style={{ width: "200px" }}
                            >
                                {negativeOptions.map((option) => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                    </div>

                    {/* Пояснение */}
                    <Input
                        className='param-select'
                        placeholder="Введите пояснение"
                        value={explanation}
                        onChange={(e) => {
                            setExplanation(e.target.value);
                            updateNote();
                        }}
                        style={{ width: "100%" }}
                    />

                    {/* СМИ (свободное поле для ввода) */}
                    <Input
                        className='param-select'
                        placeholder="Введите СМИ"
                        value={media}
                        onChange={(e) => {
                            setMedia(e.target.value);
                            updateNote();
                        }}
                        style={{ width: "100%" }}
                    />

                    {/* MT */}
                    <Input
                        className='param-select'
                        placeholder="Введите MT"
                        value={mt}
                        onChange={(e) => {
                            setMt(e.target.value);
                            updateNote();
                        }}
                        style={{ width: "100%" }}
                    />

                    {/* Итог (множество выбора) */}
                    <Select
                        className='param-select'
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Выберите итог"
                        value={result}
                        onChange={(selectedResult) => {
                            setResult(selectedResult);
                            updateNote();
                        }}
                    >
                        {resultOptions.map((res) => (
                            <Option key={res} value={res}>
                                {res}
                            </Option>
                        ))}
                    </Select>

                    {/* Меры */}
                    <Select
                        className='param-select'
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Выберите меры"
                        value={measures}
                        onChange={(selectedMeasures) => {
                            setMeasures(selectedMeasures);
                            updateNote();
                        }}
                    >
                        {measureOptions.map((measure) => (
                            <Option key={measure} value={measure}>
                                {measure}
                            </Option>
                        ))}
                    </Select>

                    {/* Кнопки */}
                    <Space style={{ width: "100%" }} align="center">
                        <Button
                            icon={<CopyOutlined />}
                            onClick={() => {
                                navigator.clipboard.writeText(note).then(() => {
                                    alert("Содержимое скопировано в буфер обмена");
                                });
                            }}
                        >
                            Копировать
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                updateNote(); // Update the note based on current selections
                            }}
                        >
                            Обновить
                        </Button>
                        <Button
                            onClick={clearAll} // Очистить все поля
                        >
                            Очистить
                        </Button>
                    </Space>
                    <Title className='box-title' level={4}>Перед копированием нажмите Обновить и проверьте корректность заметки</Title>
                </div>
            </div>
        </Space>
    );
};

export default Comment;