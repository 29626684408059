import React, { useState, useEffect } from "react";
import './Home.css';
import Comment from "../Commet/Comment";
import Median from "../Median/Median";
import {Button} from "antd";
import logo from "../logo.png"
const Home = () => {
    return (
        <div>
            <div className='logobox'>
                <img className='logo' src={logo}/>
                <div className='logo-text'>МАЙМЕДИАН</div>
                <a className='help-btn' href='https://t.me/metagaiko'>памахити</a>
            </div>
            <div className='App'>
            <Median/>
                <div>
                    <Comment />
                </div>
            </div>
        </div>
    );
};

export default Home;