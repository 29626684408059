import React from "react";
import { Button, Space, Typography } from "antd";

const { Text } = Typography;

const Recomends = () => {
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            () => {
                console.log("Copied to clipboard");
            },
            (err) => {
                console.error("Could not copy text: ", err);
            }
        );
    };

    const texts = {
        "Не дружи":
            "Просьба уведомить клиента, что расчеты с **_ (наименование и ИНН организации, ФИО) непрозрачны для Банка. Клиенту рекомендовано воздержаться от расчетов с ним по счету в Т‑Банке.",
        "Не дружи с ИП":
            "Просьба уведомить клиента, что межбанковские поступления со счета ИП клиента непрозрачны для Банка. Клиенту рекомендовано воздержаться от проведения подобных операций по счету в Т‑Банке.",
        "Транзит":
            "Клиенту рекомендовано воздержаться от массовых операций по переводам (за исключением переводов между своими счетами в Т‑Банке) и увеличить долю покупок.",
        "СЗП":
            "Мы изучили ваши документы и пояснения: операции <УКАЗЫВАЕМ КАКИЕ> остались для нас непрозрачными. Предполагаем, что вы проводите их в интересах ИП/ООО (НАИМЕНОВАНИЕ и ИНН). Рекомендуем отказаться от подобных операций по счету физического лица в Т‑Банке.",
        "НПД с СМЗ":
            "Клиенту рекомендовано отказаться от осуществления предпринимательской деятельности по счету физического лица в Т‑Банке, либо уплачивать налог на профессиональный доход в рамках деятельности самозанятого, или зарегистрироваться в качестве ИП на другом налоговом режиме и вести деятельность по счету ИП.",
        "НПД":
            "Клиенту рекомендовано отказаться от осуществления предпринимательской деятельности по счету физического лица в Т‑Банке, либо зарегистрироваться в качестве ИП и вести деятельность по счету ИП.",
        "ПД по ФЛ":
            "Клиенту рекомендовано отказаться от получения на счет физического лица оплаты за товары/услуги, реализуемые в рамках деятельности его ИП. Ограничения действуют со стороны Т‑Банка на основании инструкций ЦБ №204-И от 30.06.2021 и п. 7.2.3.2 УКБО.",
        "ЛОМ":
            "Клиенту рекомендовано отказаться от осуществления деятельности по сдаче лома по счету физического лица в Т-Банке. В соответствии с действующим законодательством при отсутствии документов, подтверждающих реальность сдачи лома, операции могут быть признаны сомнительными, что повлечет ограничение дистанционного банковского обслуживания."
    };

    return (
        <div>
            <Space  direction="vertical">
                <Text strong>При нажатии на кнопку река скопируется в буфер обмена</Text>
                <div style={{ display: "flex"}}>
                    {Object.entries(texts).map(([label, text]) => (
                        <Button
                            key={label}
                            onClick={() => copyToClipboard(text)}
                            style={{ width: "100%", margin: "10px" }}
                        >
                            {label}
                        </Button>
                    ))}
                </div>
            </Space>
        </div>
    );
};

export default Recomends;
